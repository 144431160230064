import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";

import "./NavMenu.css";
import {
  isAuthenticated,
  removeAuth,
  getUserName,
} from "../helpers/authHelper";
import { useCurrentUser, useUpdateCurrentUser } from "../contexts/UserContext";
import CurrentUserModel from "../models/CurrentUserModel";

const NavMenu = (props: any) => {
  //
  // Context
  //
  const currentUser = useCurrentUser();
  const updateCurrentUser = useUpdateCurrentUser();
  //
  // State
  //
  const [authenticated, setAuthenticated] = useState(isAuthenticated());
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof window !== "undefined") {
      //
      // Add an event listener for local storage updates
      //
      window.addEventListener("storage", localStorageUpdated);
    }
  }, []);

  //
  // Event that is fired when local storage is updated
  // This component will check if the users is still authenticated
  // If not, it will redirect the user to the login page
  //
  function localStorageUpdated() {
    const currentlyAuthenticated = isAuthenticated();
    setAuthenticated(currentlyAuthenticated);

    if (!currentlyAuthenticated) {
      //updateCurrentUser(undefined, 'NavMenu.tsx');
      navigate("/");
    }
  }

  //
  // Get menu for unauthenticated user
  //
  function getUnauthenticatedMenu() {
    const path = window.location.pathname;

    return (
      <Navbar
        bg="primary"
        variant="dark"
        expand="lg"
        expanded={expanded}
        className="px-3"
      >
        <Navbar.Brand>
          <Link
            className="navbar-brand clickable"
            onClick={() => setExpanded(false)}
            to="/"
          >
            sKRAMble
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : true)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link
              to="/teams"
              onClick={() => setExpanded(false)}
              className={
                "nav-link text-light " + (path == "/teams" ? "active" : "")
              }
            >
              Teams
            </Link>
            <Link
              to="/sponsors"
              onClick={() => setExpanded(false)}
              className={
                "nav-link text-light " + (path == "/sponsors" ? "active" : "")
              }
            >
              Sponsors
            </Link>
            {/*<Link to="/auth/team-login"*/}
            {/*    onClick={() => setExpanded(false)}*/}
            {/*    className={"nav-link text-light " + (path == '/auth/team-login' ? 'active' : '')}>Team Login*/}
            {/*</Link>*/}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }

  //
  // User log out
  //
  function handleLogout() {
    setExpanded(false);
    removeAuth();

    updateCurrentUser(undefined, "NavMenu.tsx logout");
  }

  //
  // Get menu for authenticated user
  //
  function getAuthenticatedMenu() {
    const path = window.location.pathname;

    return (
      <Navbar
        bg="primary"
        variant="dark"
        expand="lg"
        expanded={expanded}
        className="px-3"
      >
        <Navbar.Brand>
          <Link
            className="navbar-brand clickable"
            onClick={() => setExpanded(false)}
            to="/"
          >
            sKRAMble
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : true)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link
              to="/teams"
              onClick={() => setExpanded(false)}
              className={
                "nav-link text-light " + (path == "/teams" ? "active" : "")
              }
            >
              Teams
            </Link>
            <Link
              to="/leaderboard"
              onClick={() => setExpanded(false)}
              className={
                "nav-link text-light " +
                (path == "/leaderboard" ? "active" : "")
              }
            >
              Leaderboard
            </Link>
            <Link
              to="/live-scoring"
              onClick={() => setExpanded(false)}
              className={
                "nav-link text-light " + (path == "/event" ? "active" : "")
              }
            >
              Live Scoring
            </Link>
            {currentUser && currentUser.isAdmin && (
              <Dropdown>
                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                  Admin
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/events">Events</Dropdown.Item>
                  <Dropdown.Item href="/admin/team-info">
                    Team Info
                  </Dropdown.Item>
                  <Dropdown.Item href="/admin/attendees">
                    Attendees
                  </Dropdown.Item>
                  <Dropdown.Item href="/admin/volunteers">
                    Volunteers
                  </Dropdown.Item>
                  <Dropdown.Item href="/admin/shirt-requests">
                    Shirt Requests
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          {currentUser && currentUser.isAdmin && (
            <Navbar.Text>{getUserName()}</Navbar.Text>
          )}
          <Navbar.Text>
            <a
              id="logout"
              className="nav-link btn btn-link text-light ms-2"
              onClick={handleLogout}
            >
              Logout
            </a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    );
  }

  //
  // Get render details
  //
  let contents = authenticated
    ? getAuthenticatedMenu()
    : getUnauthenticatedMenu();

  //let contents = getUnauthenticatedMenu();

  return <header>{contents}</header>;
};

export default NavMenu;
