import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ReloadPageButton from './shared/ReloadPageButton';
import EventDetailsModel from '../models/EventDetailsModel';
import { useUpdateEvent } from '../contexts/EventContext';
import { useCurrentUser } from '../contexts/UserContext';
import Swal from 'sweetalert2';

const Home = () => {
    //
    // Context
    //
    const updateEvent = useUpdateEvent();
    const navigate = useNavigate();
    const currentUser = useCurrentUser();
    //
    // State
    //
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [event, setEvent] = useState<EventDetailsModel>();

    useEffect(() => {
        //
        // Get events from the server
        //
        fetch('/api/Home', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((json: EventDetailsModel) => {
                setEvent(json);
                updateEvent(json);
            });
    }, []);

    if (error) {
        return <div className="alert alert-danger">
            An error occurred.  Please reload the page.
            <ReloadPageButton></ReloadPageButton>
        </div>
    }

    interface ButtonPanelProps {
        title?: string;
        children: React.ReactNode;
    }

    //
    // Consistent panel layout for button renderings
    //
    const ButtonPanel: React.FC<ButtonPanelProps> = ({ title, children }) => (
        <div className="row">
            <div className="col-md-12 mb-3">
                {title && (
                    <div className="panel">
                        <p className="text-white mb-1">{title}</p>
                    </div>
                )}
                <div className="panel">
                    <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );

    //
    // Component for when registration is closed, but an event is currently active
    //
    const RegistrationClosed = () => (
        <ButtonPanel title="Event is currently full. View Leaderboard?">
            <Link to="leaderboard" className="btn btn-success btn-lg px-4 me-sm-3">
                Leaderboard
            </Link>
        </ButtonPanel>
    );

    //
    // Component for when the current event is full and not allowing any more
    // golf sign ups or attendee sign ups
    //
    const EventFull = () => (
        <ButtonPanel>
            <div className="alert bg-light text-bg-light" style={{ fontWeight: 'bold' }}>
                This event is full
            </div>
        </ButtonPanel>
    );

    //
    // Component for when the max golf teams has been reached, but we still
    // can allow dinner attendees
    //
    const GolfFullDinnerAvailable = () => (
        <>
            <ButtonPanel title="Interested in playing?">
                <div className="alert bg-light text-bg-light" style={{ fontWeight: 'bold' }}>
                    Teams Currently Full
                </div>
            </ButtonPanel>
            <ButtonPanel title="Not a golfer?">
                <Link to="dinner-sign-up" className="btn btn-primary btn-lg px-4 me-sm-3">
                    Dinner Sign-Up or Buy Shirts
                </Link>
            </ButtonPanel>
            <ButtonPanel title="Want to be a Sponsor?">
                <Link to="sponsor" className="btn btn-primary btn-lg px-4 me-sm-3">
                    Sponsor a Hole
                </Link>
            </ButtonPanel>
        </>
    );

    //
    // Component for when both the golf and dinner sign ups are available / active
    // 
    const StandardSignUp = () => (
        <>
            <ButtonPanel title="Interested in playing?">
                <Link to="sign-up" className="btn btn-primary btn-lg px-4 me-sm-3">
                    Sign-Up Team
                </Link>
            </ButtonPanel>
            <ButtonPanel title="Not a golfer?">
                <Link to="dinner-sign-up" className="btn btn-primary btn-lg px-4 me-sm-3">
                    Dinner Sign-Up or Buy Shirts
                </Link>
            </ButtonPanel>
            <ButtonPanel title="Want to be a Sponsor?">
                <Link to="sponsor" className="btn btn-primary btn-lg px-4 me-sm-3">
                    Sponsor a Hole
                </Link>
            </ButtonPanel>
        </>
    );

    //
    // Component for a logged in golfer - either sees the Live Scoring link or Leaderboard link
    //
    const RegisteredGolfer = () => (
        <ButtonPanel title="Welcome Golfer!">
            <Link
                to={event?.scoringIsLocked ? "leaderboard" : "live-scoring"}
                className="btn btn-success btn-lg px-4 me-sm-3"
            >
                {event?.scoringIsLocked ? "Leaderboard" : "Live Scoring"}
            </Link>
        </ButtonPanel>
    );

    const SignUpButtons = () => {
        //
        // Special case when registration is closed and there isn't a current
        // active event. This will typically show for the months following the completion
        // of a year, prior to registration for the next year being opened up
        //
        if (!event?.registrationEnabled && !event?.isEventLive) {
            return null;
        }

        if (!event.registrationEnabled && event.isEventLive) {
            return <RegistrationClosed />;
        }

        // Handle non-logged in users
        if (!currentUser) {
            if (event.attendeesAreFull) {
                return <EventFull />;
            }

            if (event.isFull) {
                return <GolfFullDinnerAvailable />;
            }

            return <StandardSignUp />;
        }

        // Handle logged-in users
        if (currentUser.teamId) {
            return <RegisteredGolfer />;
        }

        return null;
    };

    const renderDonateButton = () => {
        return <div className="panel">
            <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                <a href="https://donate.stripe.com/fZe16zeev9UNcx2288" target="_blank" className="btn btn-primary btn-lg px-4 me-sm-3">Donate</a>
            </div>
        </div>;
    }

    const openShirtModal = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        Swal.fire({
            imageUrl: '/images/2024-skramble-shirt.jpg',
            imageAlt: 'Shirt',
            showCloseButton: false,
            showConfirmButton: false
        });
    }

    return (
        <>
            <header className="bg-dark py-1">
                <div className="container px-5">
                    <div className="row gx-5 align-items-center justify-content-center">
                        <div className="col-lg-8 col-xl-7 col-xxl-6">
                            <div className="my-5 text-center text-xl-start">
                                <h1 className="display-5 fw-bolder text-white mb-2">The sKRAMble</h1>
                                <div className="col-lg-12 d-xl-none text-center">
                                    <img className="img-fluid rounded-3 my-3" src="/images/mark.jpg" alt="..."></img>
                                </div>
                                <p className="lead fw-normal text-white-50 mb-4">Year 3 of The sKRAMble - coming July 19, 2025!</p>
                                <p className="lead fw-normal text-white-50 mb-4">The annual golf outing to celebrate the life of Mark Pulice Jr.</p>
                                <p className="lead fw-normal text-white-50 mb-4">A portion of all proceeds will go to the Mark Pulice Jr. Memorial Scholarship Fund, supported by Tinley Park High School.</p>

                                {/*Event is not open yet*/}
                                {event && !event.registrationEnabled && !event.isEventLive && <div className="panel">
                                    <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                                        <div className="alert bg-light text-bg-light" style={{ fontWeight: 'bold' }}>
                                            2025 Sign-Ups Coming Soon!
                                        </div>
                                    </div>
                                </div>}
                                {/*Event is active; handle the multiple button variations that can show*/}
                                {event && <SignUpButtons />}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="panel">
                                            <p className="text-white mb-1">Want to donate?</p>
                                        </div>
                                        {renderDonateButton()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center">
                            <img className="img-fluid rounded-3 my-5" src="/images/mark.jpg" alt="..."></img>
                        </div>
                    </div>
                </div>
            </header>


            <div className="container pt-5" id="featured-3">
                <h1 className="pb-2 border-bottom">Event Details</h1>
                <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                    <div className="feature col">
                        <h2>When and Where?</h2>
                        <p>July 19th, 2025
                            <br />
                            Lincoln Oaks - Crete, IL
                        </p>
                        <p>395 E Richton Rd, Crete IL 60417</p>
                        <a href="https://www.lincolnoaksgolfcourse.com/" className="icon-link" target="_blank">
                            https://www.lincolnoaksgolfcourse.com/
                        </a>
                    </div>
                    <div className="feature col">
                        <h2>What is Included?</h2>
                        <p>
                            18 holes of golf (cart included); Range balls; Lunch; Dinner; 3 drink tickets and T-Shirt.
                            Shotgun start will begin at 1:00pm. Prizes will also be raffled off during dinner.
                        </p>
                        <p>Cash bar available at the course throughout the event.</p>
                    </div>
                    <div className="feature col">
                        <h2>Questions?</h2>
                        <p>If you have any questions about the event, are interested in providing raffle donations, or need help signing up, please contact Patrick & Jianna Cosgrave.</p>
                        <div>
                            Phone: <a href="tel:+7083346191" className="icon-link">
                                (708) 334-6191
                            </a>
                        </div>
                        <div>
                            Email: <a href="mailto:theskrambleouting@gmail.com" className="icon-link">theskrambleouting@gmail.com</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-3" id="featured-3">
                <h1 className="pb-2 border-bottom">Photo Gallery</h1>
                <p>Check out photos from the 2024 Event at Lincoln Oaks <a href="https://theluchenes.client.photos/gallery/skramble2024" target="_blank">here</a>!</p>
                <p>All photography done by Francesca Luchene.</p>
                <div className="row g-4 row-cols-1 row-cols-lg-1">
                    <div className="feature col">
                        <img className="img-fluid rounded-3" src="/images/2024-skramble-group.jpg" alt="..."></img>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;