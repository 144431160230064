import AttendeeSignUpRequest from "./AttendeeSignUpRequest";

class PaymentModel {
  requestedByEmail: string | undefined = "";
  requestedByFirstName: string | undefined = "";
  requestedByLastName: string | undefined = "";
  isTeamRequest: boolean = false;
  isAttendeeRequest: boolean = false;
  isSponsorRequest: boolean = false;
  paymentTrackingId: string | undefined = "";

  attendeeRequest: AttendeeSignUpRequest | undefined = undefined;
}

export default PaymentModel;
