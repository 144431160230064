import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import SponsorModel from "../../models/SponsorModel";
import Loading from "../shared/Loading";
import { Link } from "react-router-dom";
import { useEvent } from "../../contexts/EventContext";

const fetchSponsors = async (): Promise<SponsorModel[]> => {
  const response = await fetch("/api/sponsor/getsponsors");
  if (!response.ok) {
    throw new Error("Failed to fetch sponsors");
  }
  return response.json();
};

interface SponsorCardProps {
  sponsor: SponsorModel;
}

const SponsorCard: React.FC<SponsorCardProps> = ({ sponsor }) => {
  const [isHovered, setIsHovered] = useState(false);

  const normalizeUrl = (url: string): string => {
    if (!url) return url;
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  const handleCardClick = () => {
    if (sponsor.websiteUrl) {
      window.open(normalizeUrl(sponsor.websiteUrl), "_blank");
    }
  };

  return (
    <div
      className={`card mb-3 ${sponsor.websiteUrl ? "cursor-pointer" : ""}`}
      onClick={handleCardClick}
      onMouseEnter={() => sponsor.websiteUrl && setIsHovered(true)}
      onMouseLeave={() => sponsor.websiteUrl && setIsHovered(false)}
      style={{
        cursor: sponsor.websiteUrl ? "pointer" : "default",
        transition: "background-color 0.2s ease-in-out",
        backgroundColor: isHovered ? "rgba(0, 0, 0, 0.03)" : "transparent",
      }}
    >
      <div className="card-body d-flex flex-column align-items-center">
        <h5 className="card-title text-center mb-3">{sponsor.sponsorName}</h5>
        <img
          src={sponsor.logoUrl}
          alt={`${sponsor.sponsorName} logo`}
          className="img-fluid"
          style={{ maxHeight: "150px", objectFit: "contain" }}
        />
      </div>
    </div>
  );
};

const Sponsors = () => {
  const currentEvent = useEvent();

  const [searchQuery, setSearchQuery] = useState("");
  const {
    data: sponsors,
    isLoading,
    error,
  } = useQuery<SponsorModel[], Error>({
    queryKey: ["sponsors"],
    queryFn: fetchSponsors,
  });

  if (isLoading) {
    return <Loading message="Loading Sponsors..." />;
  }

  if (error) {
    return (
      <div className="text-center mt-5 text-danger">
        {error.message || "Failed to fetch sponsors"}
      </div>
    );
  }

  if (!sponsors || sponsors.length === 0) {
    return (
      <div className="container pt-4">
        <div className="py-lg-3 py-md-2 py-sm-2 px-2">
          <div className="row align-items-center">
            <div className="col-8">
              <h2 className="text-dark">Sponsors</h2>
            </div>
            <div className="col-4 d-flex justify-content-end">
              {currentEvent &&
                currentEvent.registrationEnabled &&
                currentEvent.isEventLive && (
                  <Link
                    to="/sponsor"
                    className="btn btn-primary d-flex align-items-center me-2"
                  >
                    <i className="bi bi-tag-fill me-2"></i> Sponsor a Hole
                  </Link>
                )}
              <Link
                to="/"
                className="btn btn-primary d-flex align-items-center"
              >
                <i className="bi bi-house me-2"></i> Home
              </Link>
            </div>
          </div>
          <hr />
          <h2>No Sponsors Found</h2>
        </div>
      </div>
    );
  }

  const filteredSponsors = sponsors.filter((sponsor) =>
    sponsor.sponsorName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container pt-4">
      <div className="py-lg-3 py-md-2 py-sm-2 px-2">
        <div className="row align-items-center">
          <div className="col-8">
            <h2 className="text-dark">Sponsors</h2>
          </div>
          <div className="col-4 d-flex justify-content-end">
            {currentEvent &&
              currentEvent.registrationEnabled &&
              currentEvent.isEventLive && (
                <Link
                  to="/sponsor"
                  className="btn btn-primary d-flex align-items-center me-2"
                >
                  <i className="bi bi-tag-fill me-2"></i> Sponsor a Hole
                </Link>
              )}
            <Link to="/" className="btn btn-primary d-flex align-items-center">
              <i className="bi bi-house me-2"></i> Home
            </Link>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12">
            <div className="alert alert-info">
              <p>Thank you to our valued {sponsors[0].eventYear} sponsors!</p>
              <p>
                Click any sponsor card below to visit their website (only
                available if sponsor provided website during sign-up.).
              </p>
            </div>
          </div>
        </div>

        <div className="my-3 row ml-3 mr-3">
          <div className="col-12">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text search-input-icon py-2">
                  <i className="bi bi-search"></i>
                </div>
              </div>
              <input
                type="text"
                className="form-control py-2 border-right-0 border"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="row ml-1 mr-1">
          {filteredSponsors.map((sponsor) => (
            <div key={sponsor.id} className="col-lg-3 col-md-6 col-sm-12">
              <SponsorCard sponsor={sponsor} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sponsors;
