// React
import React, { Component, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
// No Auth Components
import Layout from "./components/Layout";
import Home from "./components/Home";
import Login from "./components/auth/Login";
import TeamLogin from "./components/auth/TeamLogin";
import SignUp from "./components/signup/SignUp";
import DinnerSignUp from "./components/signup/DinnerSignUp";
import Payment from "./components/payment/Payment";
import Receipt from "./components/payment/Receipt";
import Teams from "./components/golf/Teams";
import Standings from "./components/golf/Standings";
import ScoreEntry from "./components/admin/ScoreEntry";
import PostPayment from "./components/payment/PostPayment";
import TeamAuth from "./components/auth/TeamAuth";
import EventDeactivated from "./components/golf/EventDeactivated";
// Auth Components
import LiveScoring from "./components/golf/LiveScoring";
import TeamInfoAdmin from "./components/admin/TeamInfoAdmin";
import ShirtRequests from "./components/admin/ShirtRequests";
import Attendees from "./components/admin/Attendees";
import EventList from "./components/golf/EventList";
import EventDetails from "./components/admin/Events/EventDetails";
import Volunteers from "./components/admin/Volunteers";
import Sponsors from "./components/sponsor/Sponsors";
import SponsorSignUp from "./components/signup/SponsorSignUp";
// Helpers
import { isAuthenticated, getAuthHeader } from "./helpers/authHelper";
import { useCurrentUser, useUpdateCurrentUser } from "./contexts/UserContext";
import { useEvent, useUpdateEvent } from "./contexts/EventContext";
import { usePayment, useUpdatePayment } from "./contexts/PaymentContext";
// Local Storage
import {
  LOCAL_STORAGE_USER,
  LOCAL_STORAGE_EVENT,
  LOCAL_STORAGE_PAYMENT,
} from "./helpers/constants";
// CSS
import "./css/_custom-variables.scss";
import "./custom.css";
import "./loading.css";
import EventDetailsModel from "./models/EventDetailsModel";

//export default class App extends Component {

const leaderboardRoute = "/leaderboard";
const liveScoringRoute = "/live-scoring";

const paymentComponents = [liveScoringRoute];

const activeEventComponents = [liveScoringRoute];

const App = () => {
  //
  // Const
  //
  const location = useLocation();
  const navigate = useNavigate();
  //
  // Context
  //
  const updateCurrentUser = useUpdateCurrentUser();
  const user = useCurrentUser();
  const updateCurrentEvent = useUpdateEvent();
  const currentEvent = useEvent();
  const updatePayment = useUpdatePayment();

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER));
    const userIsEmpty = !user || isEmptyObject(user);

    if (userIsEmpty === false) {
      updateCurrentUser(user, "App.js");
      checkForTeamPayment(user);
      getUserFromServer();
    }

    const lsCurrentEvent = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_EVENT)
    );

    if (lsCurrentEvent) {
      updateCurrentEvent(lsCurrentEvent);
    }

    getEventFromServer();

    const lsPayment = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PAYMENT));

    if (lsPayment) {
      updatePayment(lsPayment);
    }

    async function getUserFromServer() {
      //
      // Get user information from server to ensure back end and front end agree
      //
      try {
        const resp = await fetch("/api/user/get-user", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthHeader(),
          },
        });

        if (resp.status !== 200) {
          return;
        }

        const user = await resp.json();
        const userIsEmpty = !user || isEmptyObject(user);

        if (user && userIsEmpty === false) {
          updateCurrentUser(user, "App.js 2");
        }
      } catch {}
    }

    async function getEventFromServer() {
      const resp = await fetch("/api/home");

      if (resp.status !== 200) {
        return;
      }

      const eventDetails: EventDetailsModel = await resp.json();
      const eventIsEmpty = !eventDetails || isEmptyObject(eventDetails);

      if (!eventIsEmpty) {
        updateCurrentEvent(eventDetails);
      }
    }
  }, []);

  //
  // Monitor URL changes - Redirect to payment if team has not paid
  //
  useEffect(() => {
    checkForTeamPayment(user);
  }, [user, location]);

  //
  // Check if the team has paid
  //
  const checkForTeamPayment = (user) => {
    if (location && user && !user.teamHasPaid) {
      let redirect = false;

      paymentComponents.forEach((component) => {
        if (location.pathname.includes(component)) {
          redirect = true;
        }
      });

      if (redirect) {
        navigate("/payment");
      }
    }
  };

  //
  // Run checkForLiveEvent when the location (url) or event changes
  //
  useEffect(() => {
    checkForLiveEvent();
  }, [location, currentEvent]);

  //
  // Check that the event is active
  //
  const checkForLiveEvent = () => {
    if (location && currentEvent) {
      let redirect = false;

      activeEventComponents.forEach((component) => {
        if (location.pathname === component) {
          redirect = true;
        }
      });

      //
      // Redirect to event deactivated page if the event is locked
      //
      if (redirect && currentEvent && currentEvent.scoringIsLocked) {
        navigate("/event-deactivated");
      }
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
      <Route
        path="/auth/login"
        element={
          <Layout>
            <Login />
          </Layout>
        }
      />
      <Route
        path="/auth/team-login"
        element={
          <Layout>
            <TeamLogin />
          </Layout>
        }
      />
      <Route
        path="/sign-up"
        element={
          <Layout>
            <SignUp />
          </Layout>
        }
      />
      <Route
        path="/dinner-sign-up"
        element={
          <Layout>
            <DinnerSignUp />
          </Layout>
        }
      />
      <Route
        path="/sponsor"
        element={
          <Layout>
            <SponsorSignUp />
          </Layout>
        }
      />
      <Route
        path="/payment"
        element={
          <Layout>
            <Payment />
          </Layout>
        }
      />
      <Route
        path="/payment-confirmation"
        element={
          <Layout>
            <PostPayment />
          </Layout>
        }
      />
      <Route
        path="/receipt"
        element={
          <Layout>
            <Receipt />
          </Layout>
        }
      />
      <Route
        path="/teams"
        element={
          <Layout>
            <Teams />
          </Layout>
        }
      />
      <Route
        path="/sponsors"
        element={
          <Layout>
            <Sponsors />
          </Layout>
        }
      />
      <Route
        path={leaderboardRoute}
        element={
          <Layout>
            <Standings />
          </Layout>
        }
      />
      <Route
        path={liveScoringRoute}
        element={
          <RequireAuth redirectTo="/auth/login">
            <Layout>
              <LiveScoring />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/event-deactivated"
        element={
          <Layout>
            <EventDeactivated />
          </Layout>
        }
      />
      <Route
        path="/admin/score-entry/:teamId"
        element={
          <RequireAuth redirectTo="/auth/login">
            <Layout>
              <ScoreEntry />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/auth/team-auth-request/:authCode"
        element={
          <Layout>
            <TeamAuth />
          </Layout>
        }
      />
      <Route
        path="/admin/team-info"
        element={
          <RequireAuth redirectTo="/auth/login">
            <Layout>
              <TeamInfoAdmin />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/admin/attendees"
        element={
          <RequireAuth redirectTo="/auth/login">
            <Layout>
              <Attendees />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/admin/volunteers"
        element={
          <RequireAuth redirectTo="/auth/login">
            <Layout>
              <Volunteers />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/admin/shirt-requests"
        element={
          <RequireAuth redirectTo="/auth/login">
            <Layout>
              <ShirtRequests />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/events"
        element={
          <RequireAuth redirectTo="/auth/login">
            <Layout>
              <EventList />
            </Layout>
          </RequireAuth>
        }
      />
      <Route
        path="/admin/event-details/:eventId"
        element={
          <RequireAuth redirectTo="/auth/login">
            <Layout>
              <EventDetails />
            </Layout>
          </RequireAuth>
        }
      />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

function RequireAuth({ children, redirectTo }) {
  let validAuth = isAuthenticated();

  return validAuth ? children : <Navigate to={redirectTo} />;
}

export default App;
