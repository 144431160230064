import { useState, ChangeEvent, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useEvent } from "../../contexts/EventContext";
import SponsorSignUpRequest from "../../models/SponsorSignUpRequest";
import { showErrorAlert } from "../../helpers/alertHelper";
import { useUpdatePayment } from "../../contexts/PaymentContext";
import PaymentModel from "../../models/PaymentModel";

interface ValidationErrors {
  contactEmail?: string;
  contactPhoneNumber?: string;
  websiteUrl?: string;
}

interface SponsorSignUpResponse {
  paymentTrackingId: string;
}

const submitSponsorSignUp = async (
  formData: FormData
): Promise<SponsorSignUpResponse> => {
  const response = await fetch("/api/signup/sponsor-sign-up", {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Failed to submit sponsor sign-up");
  }

  return response.json();
};

const SponsorSignUp = () => {
  const navigate = useNavigate();
  const currentEvent = useEvent();
  const updatePayment = useUpdatePayment();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [sponsor, setSponsor] = useState<SponsorSignUpRequest>({
    sponsorName: "",
    contactFirstName: "",
    contactLastName: "",
    contactEmail: "",
    contactPhoneNumber: "",
    websiteUrl: null,
    logo: null,
  });
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(
    {}
  );
  const [formValidity, setFormValidity] = useState({
    sponsorNameValid: false,
    firstNameValid: false,
    lastNameValid: false,
    emailValid: false,
    phoneValid: false,
  });

  const sponsorSignUpMutation = useMutation({
    mutationFn: submitSponsorSignUp,
    onSuccess: (data) => {
      const newPayment = new PaymentModel();
      newPayment.isSponsorRequest = true;
      newPayment.paymentTrackingId = data.paymentTrackingId;
      newPayment.requestedByEmail = sponsor.contactEmail;
      updatePayment(newPayment);
      navigate("/payment");
    },
    onError: (error) => {
      showErrorAlert();
      console.error("Submission error:", error);
    },
  });

  const defaultLogoUrl = "/images/skramble-logo-main.png";

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone: string): boolean => {
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return phoneRegex.test(phone);
  };

  const formatPhoneNumber = (phone: string): string => {
    const cleaned = phone.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phone;
  };

  const validateWebsiteUrl = (url: string): boolean => {
    // Optional field, so empty is valid
    if (!url) {
      return true;
    }
    const urlRegex =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i;
    return urlRegex.test(url);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    let updatedValue = value;
    const newErrors = { ...validationErrors };
    const newValidity = { ...formValidity };

    setSponsor((prev) => ({
      ...prev,
      [name]: updatedValue,
    }));

    switch (name) {
      case "sponsorName":
        newValidity.sponsorNameValid = updatedValue.trim().length > 0;
        break;
      case "contactFirstName":
        newValidity.firstNameValid = updatedValue.trim().length > 0;
        break;
      case "contactLastName":
        newValidity.lastNameValid = updatedValue.trim().length > 0;
        break;
      case "contactEmail":
        if (value && !validateEmail(value)) {
          newErrors.contactEmail = "Please enter a valid email address";
          newValidity.emailValid = false;
        } else {
          delete newErrors.contactEmail;
          newValidity.emailValid =
            value.trim().length > 0 && validateEmail(value);
        }
        break;
      case "contactPhoneNumber":
        updatedValue = value.replace(/\D/g, "").slice(0, 10);
        if (updatedValue.length === 10) {
          updatedValue = formatPhoneNumber(updatedValue);
        }
        if (value && !validatePhoneNumber(updatedValue)) {
          newErrors.contactPhoneNumber =
            "Please enter a valid 10-digit phone number";
          newValidity.phoneValid = false;
        } else {
          delete newErrors.contactPhoneNumber;
          newValidity.phoneValid = validatePhoneNumber(updatedValue);
        }

        setSponsor((prev) => ({
          ...prev,
          [name]: updatedValue,
        }));
        break;
      case "websiteUrl":
        if (value && !validateWebsiteUrl(value)) {
          newErrors.websiteUrl = "Please enter a valid website URL";
        } else {
          delete newErrors.websiteUrl;
        }
        break;
    }

    setValidationErrors(newErrors);
    setFormValidity(newValidity);
  };

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        alert("File size exceeds 10MB limit. Please choose a smaller file.");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return;
      }

      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        alert("Please upload only image files (.png, .jpg, .jpeg)");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return;
      }

      setSponsor((prev) => ({
        ...prev,
        logo: file,
      }));
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current?.click();
  };

  const isFormValid = (): boolean => {
    return Object.values(formValidity).every((value) => value === true);
  };

  const processRequest = () => {
    const formData = new FormData();
    formData.append("sponsorName", sponsor.sponsorName);
    formData.append("contactFirstName", sponsor.contactFirstName);
    formData.append("contactLastName", sponsor.contactLastName);
    formData.append("contactEmail", sponsor.contactEmail);
    formData.append("contactPhoneNumber", sponsor.contactPhoneNumber);
    if (sponsor.websiteUrl) {
      formData.append("websiteUrl", sponsor.websiteUrl);
    }
    if (sponsor.logo) {
      formData.append("logo", sponsor.logo);
    }

    sponsorSignUpMutation.mutate(formData);
  };

  return (
    <div className="container pt-4">
      <div className="row">
        <div className="col-8">
          <h2 className="text-dark">Sponsor a Hole</h2>
        </div>
        <div className="col-4 text-end pb-2">
          <Link to="/" className="btn btn-primary">
            <i className="bi bi-house"></i> Home
          </Link>
        </div>
        <hr />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="alert alert-info">
            <p>
              To sponsor a hole on behalf of yourself, your family, or your
              organization, please complete the form below.
            </p>
            <p>
              You can upload a custom logo for your sponsorship sign—preferably
              a high-resolution image with a transparent background.
            </p>
            <p>
              Alternatively, we'll create your sign using the "Company/Sponsor
              Name" you provide if no custom logo is uploaded.
            </p>
            {currentEvent && (
              <ul style={{ marginTop: "12px", marginBottom: "0" }}>
                <li>
                  <b>Cost</b> - ${currentEvent.sponsorFee} per sponsorship
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="mb-3">
            <div className="form-floating my-2">
              <input
                className="form-control"
                name="sponsorName"
                value={sponsor.sponsorName}
                onChange={handleInputChange}
                required
              />
              <label className="form-label">Company/Sponsor Name</label>
            </div>
            {!sponsor.sponsorName && (
              <div className="required-input">
                Company/Sponsor Name is required
              </div>
            )}
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <div className="form-floating my-2">
                <input
                  className="form-control"
                  id="contactFirstName"
                  name="contactFirstName"
                  value={sponsor.contactFirstName}
                  onChange={handleInputChange}
                  required
                />
                <label className="form-label">Primary Contact First Name</label>
              </div>
              {!sponsor.contactFirstName && (
                <div className="required-input">
                  Primary Contact First Name is required
                </div>
              )}
            </div>

            <div className="col-md-6">
              <div className="form-floating my-2">
                <input
                  className="form-control"
                  id="contactLastName"
                  name="contactLastName"
                  value={sponsor.contactLastName}
                  onChange={handleInputChange}
                  required
                />
                <label className="form-label">Primary Contact Last Name</label>
              </div>
              {!sponsor.contactLastName && (
                <div className="required-input">
                  Primary Contact Last Name is required
                </div>
              )}
            </div>
          </div>

          <div className="mb-3">
            <div className="form-floating my-2">
              <input
                className={`form-control ${
                  validationErrors.contactEmail ? "is-invalid" : ""
                }`}
                id="contactEmail"
                name="contactEmail"
                type="email"
                value={sponsor.contactEmail}
                onChange={handleInputChange}
                required
                pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
              />
              <label className="form-label">Primary Contact Email</label>
            </div>
            {!sponsor.contactEmail && (
              <div className="required-input">
                Primary Contact Email is required
              </div>
            )}
            {validationErrors.contactEmail && (
              <div className="required-input">
                {validationErrors.contactEmail}
              </div>
            )}
          </div>

          <div className="mb-3">
            <div className="form-floating my-2">
              <input
                className={`form-control ${
                  validationErrors.contactPhoneNumber ? "is-invalid" : ""
                }`}
                id="contactPhoneNumber"
                name="contactPhoneNumber"
                type="tel"
                value={sponsor.contactPhoneNumber}
                onChange={handleInputChange}
                required
              />
              <label className="form-label">Primary Contact Phone Number</label>
            </div>
            {!sponsor.contactPhoneNumber && (
              <div className="required-input">
                Primary Contact Phone number is required
              </div>
            )}
            {validationErrors.contactPhoneNumber && (
              <div className="required-input">
                {validationErrors.contactPhoneNumber}
              </div>
            )}
          </div>

          <div className="mb-3">
            <div className="form-floating my-2">
              <input
                className={`form-control ${
                  validationErrors.websiteUrl ? "is-invalid" : ""
                }`}
                id="websiteUrl"
                name="websiteUrl"
                value={sponsor.websiteUrl || ""}
                onChange={handleInputChange}
              />
              <label className="form-label">Website URL (Optional)</label>
            </div>
            {validationErrors.websiteUrl && (
              <div className="required-input">
                {validationErrors.websiteUrl}
              </div>
            )}
          </div>

          <div className="mb-3">
            <input
              ref={fileInputRef}
              id="logo"
              name="logo"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="d-none"
              aria-label="Upload company logo"
            />
            <button
              type="button"
              onClick={handleFileButtonClick}
              className="btn btn-primary w-100 py-3 mb-2"
            >
              Choose Custom Logo
            </button>
            <small className="text-muted d-block mb-2">
              Maximum file size: 10MB. Supported formats: .png, .jpg, .jpeg
            </small>
            <div className="mt-2 p-4 border rounded text-center bg-light">
              <p className="text-muted mb-3">Logo Preview</p>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "300px" }}
              >
                <img
                  src={previewUrl || defaultLogoUrl}
                  alt="Logo preview"
                  className="img-fluid"
                  style={{
                    maxHeight: "280px",
                    maxWidth: "100%",
                    width: "auto",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mb-3">
            <button
              className="btn btn-success w-100 py-3"
              disabled={!isFormValid() || sponsorSignUpMutation.isPending}
              onClick={processRequest}
            >
              {sponsorSignUpMutation.isPending
                ? "Processing..."
                : "Proceed to Payment"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponsorSignUp;
