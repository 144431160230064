// Component that shows all teams and members
import { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TeamModel from "../../models/TeamModel";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import Loading from "../shared/Loading";
import { useCurrentUser } from "../../contexts/UserContext";

const Teams = () => {
  //
  // Context
  //
  const user = useCurrentUser();
  //
  // State
  //
  const navigate = useNavigate();
  const [teams, setTeams] = useState<TeamModel[]>();
  const [visibleTeams, setVisibleTeams] = useState<TeamModel[]>();
  const [searchParams] = useSearchParams();

  // Get teams on page load
  useEffect(() => {
    // Get teams from the server
    fetch("/api/Golf/GetTeams")
      .then((response) => response.json())
      .then((json) => {
        setTeams(json);
        setVisibleTeams(json);
      });

    //
    // If we route to this page with query params, and one of the is "redirect_status" with a status
    // of "succeeded", we routed here from a successful payment.  Show a toast message.
    //
    if (
      searchParams !== undefined &&
      searchParams.get("redirect_status") !== undefined &&
      searchParams.get("redirect_status") == "succeeded"
    ) {
      toast.success("Team registered successfully!", {
        duration: 5000,
      });
    }
  }, []);

  //
  // Get the name of the team leader
  //
  const getTeamLeaderName = (team: TeamModel) => {
    if (!team.teamLeader) {
      return "No team leader";
    }

    return team.teamLeader.firstName + " " + team.teamLeader.lastName;
  };

  if (!teams) {
    return (
      <div className="m-2">
        <Loading />
      </div>
    );
  }

  // If teams are not loaded, return a "no teams..." message
  if (teams?.length === 0) {
    return (
      <div className="container pt-4">
        <div className="py-lg-3 py-md-2 py-sm-2 px-2">
          <div className="row">
            <div className="col-8">
              <h2 className="text-dark">Teams</h2>
            </div>
            <div className="col-4 text-end pb-2">
              <Link to="/" className="btn btn-primary">
                <i className="bi bi-house"></i> Home
              </Link>
            </div>
            <hr />
          </div>
          <h2>No Teams Found</h2>
        </div>
      </div>
    );
  }

  //
  // Search for a team by team number, team leader name, or team member name
  //
  function handleSearch(e: ChangeEvent<HTMLInputElement>): void {
    //// Search for a team by team number, team leader name, or team member name
    const search = e.target.value.toLowerCase();

    const filteredTeams = teams!.filter((team) => {
      if (team.teamNumber!.toString().includes(search)) {
        return true;
      }

      if (
        team.teamMembers?.some((member) =>
          member.fullName!.toLowerCase().includes(search)
        )
      ) {
        return true;
      }

      if (team.teamLeader?.fullName!.toLowerCase().includes(search)) {
        return true;
      }

      return false;
    });

    setVisibleTeams(filteredTeams);
  }

  const handleEditTeamClick = (teamId: number) => {
    //navigate('/admin/score-entry', { state: { teamId: teamId } });
    navigate("/admin/score-entry/" + teamId);
  };

  return (
    <div className="container pt-4">
      <div className="py-lg-3 py-md-2 py-sm-2 px-2">
        <div className="row">
          <div className="col-8">
            <h2 className="text-dark">Teams</h2>
          </div>
          <div className="col-4 text-end pb-2">
            <Link to="/" className="btn btn-primary">
              <i className="bi bi-house"></i> Home
            </Link>
          </div>
          <hr />
        </div>
        <div className="my-3 row ml-3 mr-3">
          <div className="col-12">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text search-input-icon py-2">
                  <i className="bi bi-search"></i>
                </div>
              </div>
              <input
                className="form-control py-2 border-right-0 border"
                type="search"
                placeholder="Search"
                autoFocus
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
        </div>
        <div className="row ml-1 mr-1">
          {visibleTeams!.map((team) => (
            <div key={team.id} className="col-lg-3 col-md-6 col-sm-12">
              <div className="card mb-3">
                <div className="card-header">
                  <div className="flex space-between">
                    <div>
                      <div
                        className="h5 card-title"
                        style={{ marginBottom: "0" }}
                      >
                        Team #{team.teamNumber}
                      </div>
                    </div>
                    {/*TODO: Display this button only if the current user is an admin*/}
                    {user && user.isAdmin && (
                      <div>
                        <i
                          onClick={() => {
                            handleEditTeamClick(team.id);
                          }}
                          style={{ cursor: "pointer" }}
                          className="bi bi-plus-circle-fill"
                        ></i>
                      </div>
                    )}
                  </div>
                </div>
                <div className="card-body">
                  {team.teamMembers?.map((member) => {
                    if (member.isTeamLeader) {
                      return (
                        <h5 key={member.id} className="card-title">
                          {member.fullName}
                        </h5>
                      );
                    }

                    return <div key={member.id}>{member.fullName}</div>;
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Teams;
