import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './contexts/UserContext';
import { EventProvider } from './contexts/EventContext';
import { PaymentProvider } from './contexts/PaymentContext';
import ScrollToTop from './components/shared/ScrollToTop';

import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const queryClient = new QueryClient();

root.render(
    <BrowserRouter basename={baseUrl}>
        <QueryClientProvider client={queryClient}>
            <EventProvider>
                <UserProvider>
                    <PaymentProvider>
                        <ScrollToTop />
                        <App />
                    </PaymentProvider>
                </UserProvider>
            </EventProvider>
        </QueryClientProvider>
    </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
